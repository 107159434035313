import * as React from "react";
import weekFormatter from "../../utils/weekFormatter";
import {NameType, Payload, ValueType} from "recharts/types/component/DefaultTooltipContent";
import {DashBoardTranslations} from "./Model/Interfaces";

export const TooltipChartDashboard = (props:{active?: boolean, data?: Payload<ValueType, NameType>[], translations:DashBoardTranslations} ) => {
    if (!props.active) {
        return null;
    }
    if(!props.data) {
        return <div className="dashboard-tooltip"/>;
    }
    const {payload} = props.data[0];
    return (
        <div className="dashboard-tooltip">
            <div className="dashboard-tooltip-arrow"/>
            <div className="dashboard-tooltip-inner">
                <p><u>{weekFormatter(payload.week)}</u></p>
                <p>
                    <span className="info-square malware-square"/>
                    {(props.translations.TR_MALWARE_DETECTED)}: {payload.malware}
                </p>
                <p>
                    <span className="info-square exposed-square"/>
                    {props.translations.TR_EXPOSED_ACCOUNTS}: {payload.exposed}
                </p>
            </div>
        </div>
    );
};