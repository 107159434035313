import React from 'react';
import {FormRenderData, Module} from '@cuatroochenta/co-admin-react-library';
import CustomAction from './CustomAction';

import '@cuatroochenta/co-admin-react-library/lib/index.css';
import './res/scss/custom-styles.scss'
import DashBoardScreen from "./Modules/Dashboard/DashBoardScreen";

interface Filters {
    [dynamic: string]: string
}

class App extends React.Component {

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private customButtonsList = (entity: string, filters: Filters, orderby: string, order: string): React.ReactNode => {
        return null;
    };

    private afterLoginButton = (): React.ReactNode => {
        return null;
    };

    public render() {
        const languages = [
            {name: 'Español', code: 'es'},
        ]

        return (
            <Module
                actions={[
                    { name: 'custom', element: <CustomAction/> }
                ]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
                afterLoginButton={this.afterLoginButton}
                disableRefreshToken={true}
                homePage={<DashBoardScreen/>}
                languages={languages}
                locale={'es'}
            />
        );
    }

}

export default App;
