import React, {ReactNode} from "react";

interface InfoPercentageDashBoardProps {
    colClasses: string,
    percentage: number,
    number: number,
    description: string,
    period: string,
    onClick?: () => void
}

class InfoPercentageDashBoard extends React.Component<InfoPercentageDashBoardProps> {

    private showPercentageDashBoard = () =>
    {
        if (this.props.percentage === 0) {
            return (
                <span className="data-same">
                {this.props.percentage}%
            </span>
            );
        }

        if (this.props.percentage < 0) {
            return (
                <span className="data-down">
                {this.props.percentage}%
            </span>
            );
        }

        return (
            <span className="data-up">
            +{this.props.percentage}%
        </span>
        );
    };

    public render(): ReactNode {
        return (
            <div
                className={this.props.colClasses}
                onClick={this.props.onClick}
            >
                <h3>
                    <span className="large-text">
                        {this.props.number}
                    </span>&nbsp;
                    { this.showPercentageDashBoard() }
                </h3>
                <p>
                    {this.props.description}&nbsp;
                    <span className="text-nowrap">
                        ({this.props.period})
                    </span></p>
            </div>
        );
    }
}

export default InfoPercentageDashBoard;