import React, {ReactNode, RefObject} from "react";
import PerfectScrollbar from "perfect-scrollbar";
import {RouteComponentProps, withRouter} from "react-router";
import UserInfoDashBoard from "./UserInfoDashBoard";
import VulnerabilityDashBoard from "./VulnerabilityDashBoard";
import VpnInfoDashBoard from "./VpnInfoDashBoard";
import {DashBoardInfo} from "./Model/Interfaces";
import TaskGetDashBoard from "../ws/Task/TaskGetDashBoard";
import {FilterState, GoToLinkWithFiltersAndOrder, LoadingView} from "@cuatroochenta/co-admin-react-library";
import '../../res/scss/dashboard.scss'
import TaskGetMessages from "../ws/Task/TaskGetMessages";
import DashBoardMessages from "./DashBoardMessages";

interface DashBoardProps {
    entity: string,
    id: string,
    loadingInfo?: string,
    filters?: FilterState
}

export interface EntityRouterProps extends RouteComponentProps, DashBoardProps {
}

let ps: PerfectScrollbar;

class DashBoardScreen extends React.Component<EntityRouterProps> {

    public state = {
        waiting: true,
        canRender: true,
        error: {} as any,
        notfound: false,
        path: '',
        data: {} as DashBoardInfo,
        messages: [] as string[],
    };

    public readonly mainPanel: RefObject<any>;

    constructor(props: any) {
        super(props);
        this.mainPanel = React.createRef();
    }

    public componentWillMount(): void {
        new TaskGetDashBoard()
            .onSuccess((data) => {
                this.setState({canRender: true, data, waiting: false});
            })
            .onFail((error) => {
                this.setState({error, waiting: false});
            })
            .execute();
        new TaskGetMessages()
            .onSuccess((data) => {
                this.setState({messages: data});
            })
            .execute();
    }

    public componentDidMount() {
        if (this.state.canRender) {
            if (navigator.platform.indexOf("Win") > -1) {
                ps = new PerfectScrollbar(this.mainPanel.current as string);
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        }
    }

    public componentWillUnmount() {
        if (this.state.canRender && navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }

    public componentDidUpdate(prevProps: Readonly<EntityRouterProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (
            this.props.history.location.pathname &&
            this.props.history.location.pathname !== this.state.path &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            this.mainPanel.current.scrollTop = 0;
            this.setState({path: this.props.history.location.pathname})
        }
        if (this.props.entity !== prevProps.entity) {
            this.setState({waiting: false});
            document.getElementsByClassName("main-panel")[0].scrollTop = 0;
        }
    }

    public render(): ReactNode {
        if (this.state.canRender) {
            const loading = this.props.loadingInfo || this.state.waiting;
            return (
                <div className="main-panel" id="dashboard-screen" ref={this.mainPanel}>
                    {
                        loading ?
                            <LoadingView loading={true}/> :
                            <div className="content mt-4">
                                <DashBoardMessages messages={this.state.messages} />
                                <UserInfoDashBoard
                                    userDashBoardInfo={this.state.data.user}
                                    translations={this.state.data.translations}
                                    goToLink={
                                        (entity, orderBy, orderAsc) =>
                                            GoToLinkWithFiltersAndOrder(entity, this.props.history, this.props.filters, orderBy, orderAsc)
                                    }
                                />
                                {
                                    <VulnerabilityDashBoard
                                        vulnerabilities={this.state.data.vulnerability}
                                        dataGraph={this.state.data.dataGraph}
                                        translations={this.state.data.translations}
                                        goToLink={
                                            (entity, orderBy, orderAsc) =>{
                                                GoToLinkWithFiltersAndOrder(entity, this.props.history, this.props.filters, orderBy, orderAsc)}
                                        }
                                    />
                                }
                                {
                                    this.state.data.vpn && <VpnInfoDashBoard
                                        vpnInfo={this.state.data.vpn}
                                        translations={this.state.data.translations}
                                        goToLink={
                                            (entity, orderBy, orderAsc) =>
                                                GoToLinkWithFiltersAndOrder(entity, this.props.history, this.props.filters, orderBy, orderAsc)
                                        }
                                    />
                                }
                            </div>
                    }
                </div>
            );
        }
        if (this.state.notfound) {
            this.props.history.push('/');
        }
        return <></>
    }
}

export default withRouter((DashBoardScreen) as any) as React.ComponentType;
