import Urls from "../Urls";
import {METHOD} from "@cuatroochenta/co-generic-request";
import {BaseGenericRequest} from "@cuatroochenta/co-admin-react-library";

interface TaskGetDashBoardResponse {
    success: boolean,
    message: string
}

export default class TaskGetDashBoard extends BaseGenericRequest<{}, TaskGetDashBoardResponse> {

    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_GET_DASHBOARD);
    }
}