import React, {ReactNode} from "react";
import InfoPercentageDashBoard from "./InfoPercentageDashBoard";
import {DashBoardTranslations, VpnDashBoardInfo} from "./Model/Interfaces";

interface VpnInfoDashBoardProps {
    vpnInfo: VpnDashBoardInfo,
    translations: DashBoardTranslations,
    goToLink: (entity: string, orderBy?: string, orderAsc?: boolean) => void
}

class VpnInfoDashBoard extends React.Component<VpnInfoDashBoardProps>
{
    public render(): ReactNode
    {
        if (!this.props.vpnInfo) {
            return null;
        }
        
        const entityToLink = 'vpnconnection';

        return (
            <div className="card p-3" id="vpn-dashboard-info">
                <div className="row">
                    <div className="col-12 border-bottom border-light mb-3 pb-2">
                        <h2 className="link" onClick={() => this.props.goToLink(entityToLink)}>
                            {this.props.translations.TR_VPN}
                        </h2>
                    </div>
                    <InfoPercentageDashBoard
                        colClasses="col-sm-3 link"
                        percentage={this.props.vpnInfo.percentageDifferenceActiveUsersPreviousWeek}
                        number={this.props.vpnInfo.numberActiveUsersLastWeek}
                        description={this.props.translations.TR_ACTIVE_USERS} period={this.props.translations.TR_LAST_WEEK}
                        onClick={() => this.props.goToLink(entityToLink)}
                    />
                    <InfoPercentageDashBoard
                        colClasses="col-sm-3 link"
                        percentage={this.props.vpnInfo.percentageDifferenceActiveUsersPreviousMonth}
                        number={this.props.vpnInfo.numberActiveUsersLastMonth}
                        description={this.props.translations.TR_ACTIVE_USERS} period={this.props.translations.TR_LAST_MONTH}
                        onClick={() => this.props.goToLink(entityToLink)}
                    />
                    <InfoPercentageDashBoard
                        colClasses="col-sm-3 link"
                        percentage={this.props.vpnInfo.percentageDifferenceMeanHoursUsedPreviousMonth}
                        number={this.props.vpnInfo.meanHoursUsedLastMonth}
                        description={this.props.translations.TR_MEAN_HOURS_USERS} period={this.props.translations.TR_LAST_MONTH}
                        onClick={() => this.props.goToLink(entityToLink)}
                    />
                    <InfoPercentageDashBoard
                        colClasses="col-sm-3 link"
                        percentage={this.props.vpnInfo.percentageDifferenceMeanTransferDataPreviousMonth}
                        number={this.props.vpnInfo.meanTransferDataLastMonth}
                        description={this.props.translations.TR_MEAN_DATA_TRANSFERRED_USERS} period={this.props.translations.TR_LAST_MONTH}
                        onClick={() => this.props.goToLink(entityToLink)}
                    />
                </div>
            </div>
        );
    }
}

export default VpnInfoDashBoard;
