import React, {ReactNode} from "react";
import VulnerabilityChart from "./VulnerabilityChart";
import InfoPercentageDashBoard from "./InfoPercentageDashBoard";
import {DashBoardTranslations, DataGraph, VulnerabilityData} from "./Model/Interfaces";

interface VulnerabilityDashBoardProps {
    vulnerabilities: VulnerabilityData,
    dataGraph: DataGraph[],
    translations: DashBoardTranslations,
    goToLink: (entity: string, orderBy?: string, orderAsc?: boolean) => void
}

class VulnerabilityDashBoard extends React.Component<VulnerabilityDashBoardProps> {

    public renderChart(): ReactNode {
        return (
            <div className="col-12 mb-2" id="vulnerability-chart">
                <VulnerabilityChart data={this.props.dataGraph} translations={this.props.translations}/>
            </div>
        );
    }

    public renderInfo(): ReactNode {
        return (
            <div id="vulnerability-info" className="col-12">
                <div className="row">
                    <InfoPercentageDashBoard
                        colClasses="col-sm-3 vulnerability-percentage link"
                        percentage={this.props.vulnerabilities.percentageDifferenceMalwarePreviousWeek}
                        number={this.props.vulnerabilities.malwareLastWeek}
                        description={this.props.translations.TR_MALWARE_DETECTED} period={this.props.translations.TR_LAST_WEEK}
                        onClick={() => this.props.goToLink('scan', 'Scan.scanDate', false)}
                    />
                    <InfoPercentageDashBoard
                        colClasses="col-sm-3 vulnerability-percentage link"
                        percentage={this.props.vulnerabilities.percentageDifferenceMalwarePreviousMonth}
                        number={this.props.vulnerabilities.malwareLastMonth}
                        description={this.props.translations.TR_MALWARE_DETECTED} period={this.props.translations.TR_LAST_MONTH}
                        onClick={() => this.props.goToLink('scan', 'Scan.scanDate', false)}
                    />
                    <InfoPercentageDashBoard
                        colClasses="col-sm-3 vulnerability-percentage link"
                        percentage={this.props.vulnerabilities.percentageDifferenceUsersWithExposeAccountsPreviousWeek}
                        number={this.props.vulnerabilities.numberUsersWithExposeAccountsLastWeek}
                        description={this.props.translations.TR_SCANNERS_NUMBER_HAVE_DETECTED_VULNERABILITY} period={this.props.translations.TR_LAST_WEEK}
                        onClick={() => this.props.goToLink('scan', 'Scan.haveIBeenPwned', false)}
                    />
                    <InfoPercentageDashBoard
                        colClasses="col-sm-3 vulnerability-percentage link"
                        percentage={this.props.vulnerabilities.percentageDifferenceUsersWithExposeAccountsPreviousMonth}
                        number={this.props.vulnerabilities.numberUsersWithExposeAccountsLastMonth}
                        description={this.props.translations.TR_SCANNERS_NUMBER_HAVE_DETECTED_VULNERABILITY} period={this.props.translations.TR_LAST_MONTH}
                        onClick={() => this.props.goToLink('scan', 'Scan.haveIBeenPwned', false)}
                    />
                </div>
            </div>
        );
    }

    public render(): ReactNode {
        return (
            <div className="card p-3">
                <div className="row">
                    <div className="col-12 border-bottom border-light mb-3 pb-2">
                        <h2 className="link" onClick={() => this.props.goToLink('scan')}>
                            {(this.props.translations.TR_VULNERABILITY)}
                        </h2>
                    </div>
                    { this.renderChart() }
                    { this.renderInfo() }
                </div>
            </div>
        );
    }
}

export default VulnerabilityDashBoard;