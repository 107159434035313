import React, {ReactNode} from "react";

interface MessagesProps {
    messages: string[]
}

class DashBoardMessages extends React.Component<MessagesProps> {
    public state = {
        indexClosed: {} as { [dynamic: string]: boolean }
    }

    private getMessages(): ReactNode {
        if (this.props.messages.length === 0) {
            return;
        }

        return this.props.messages.map((message: string, key: number) => {
            if (this.state.indexClosed[key]) {
                return
            }

            return <div className="alert alert-danger alert-dismissible fade show">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                        style={{fontSize: '2em', border: 'none', background: 'none',}} onClick={() => this.hideAlert(key)}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <p>{message}</p>
            </div>
        })
    }

    private hideAlert(key: number) {
        this.setState({
            indexClosed: {
                ...this.state.indexClosed,
                [key]: true
            }
        })
    }

    public render(): ReactNode {
        return <div>
            {this.getMessages()}
        </div>
    }
}

export default DashBoardMessages;