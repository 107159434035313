import * as React from "react";
import {ReactNode} from "react";
import {TooltipChartDashboard} from "./TooltipChartDashboard";
import {DashBoardTranslations, DataGraph} from "./Model/Interfaces";
import weekFormatter from "../../utils/weekFormatter";
import {
    AreaChart,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {Formatter} from "recharts/types/component/DefaultLegendContent";

interface LineProps {
    data: DataGraph[];
    translations: DashBoardTranslations
}

function renderColorfulLegendText(value: any): ReactNode {
    return <span style={{color: 'black'}}>{value}</span>;
}

const VulnerabilityChart = (props: LineProps) => {
    const max = props.data.length;

    if (max <= 0) {
        return null;
    }

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={props.data} key={1}>
                <AreaChart margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}/>
                <Line
                    type="monotone"
                    dataKey="malware"
                    activeDot={{r: 8}}
                    stroke={`#fc614b`}
                    strokeWidth={4}
                    name={(props.translations.TR_MALWARE_DETECTED)}
                />
                <Line
                    type="monotone"
                    dataKey="exposed"
                    activeDot={{r: 8}}
                    stroke={`#a4a9e7`}
                    strokeWidth={4}
                    name={(props.translations.TR_EXPOSED_ACCOUNTS)}
                />
                <XAxis
                    dataKey="week"
                    angle={-45}
                    textAnchor="end"
                    height={100}
                    stroke="#333"
                    tickFormatter={(week: number) => weekFormatter(week)}
                    ticks={props.data.map((graph) => graph.week)}
                    domain={[props.data[0].week, props.data[max - 1].week]}
                    allowDecimals={false}
                    type={"number"}
                    tickCount={max}
                />
                <YAxis
                    width={150}
                    stroke="#333"
                    domain={[0, "dataMax + 1"]}
                    allowDecimals={false}
                    type={"number"}
                    tickMargin={5}
                />
                <Tooltip
                    content={
                        ({active, payload}) =>
                            <TooltipChartDashboard active={active} data={payload} translations={props.translations}/>
                    }
                    contentStyle={{background: "#fff"}}
                />
                <Legend
                    verticalAlign="top"
                    iconType="square"
                    wrapperStyle={{paddingBottom: "15px"}}
                    align="center"
                    formatter={renderColorfulLegendText as Formatter}
                />
                <CartesianGrid strokeWidth={1} opacity={0.3}/>
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default VulnerabilityChart;
