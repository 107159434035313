import React, {ReactNode} from "react";
import {DashBoardTranslations, UserDashBoardInfo} from "./Model/Interfaces";

interface UserInfoDashBoardProps {
    userDashBoardInfo: UserDashBoardInfo,
    translations: DashBoardTranslations,
    goToLink: (entity: string, orderBy?: string, orderAsc?: boolean) => void,
}

class UserInfoDashBoard extends React.Component<UserInfoDashBoardProps> {

    public render(): ReactNode
    {
        return (
            <div className="card p-3" id="user-info-dashboard">
                <div className="row">
                    <div className="col-12 border-bottom border-light mb-3 pb-2">
                        <h2 className="link" onClick={() => this.props.goToLink('user')}>
                            {(this.props.translations.TR_USERS)}
                        </h2>
                    </div>
                    <div className="col-md-5 col-sm-4">
                        <h3>
                            <span className="large-text link" onClick={() => this.props.goToLink('user')}>
                                {this.props.userDashBoardInfo.numberCreatedUsers}
                            </span>
                        </h3>
                        <p className="link" onClick={() => this.props.goToLink('user')}>
                            {(this.props.translations.TR_CREATED_USERS)}
                        </p>
                    </div>
                    <div className="col-md-5 col-sm-4">
                        <h3>
                            <span className="large-text link" onClick={() => this.props.goToLink('user')}>
                                {this.props.userDashBoardInfo.numberInactiveUsersLastWeek}
                            </span>
                        </h3>
                        <p className="link" onClick={() => this.props.goToLink('user')}>
                            {(this.props.translations.TR_INACTIVE)} ({(this.props.translations.TR_LAST_WEEK)})
                        </p>
                    </div>
                    <div className="col-md-5 col-sm-4">
                        <h3>
                            <span
                                className="large-text link"
                                onClick={() => this.props.goToLink('scan', 'Scan.haveIBeenPwned', false)}
                            >
                                {this.props.userDashBoardInfo.numberUsersWithExposeAccounts}
                            </span>
                        </h3>
                        <p
                            className="link"
                            onClick={() => this.props.goToLink('scan', 'Scan.haveIBeenPwned', false)}
                        >
                            {(this.props.translations.TR_WITH_EXPOSED_ACCOUNTS)}
                        </p>
                    </div>
                    <div className="col-md-5 col-sm-4">
                        <h3>
                            <span
                                className="large-text link"
                                onClick={() => this.props.goToLink('scan', 'Scan.result', false)}
                            >
                                {this.props.userDashBoardInfo.numberUsersWithMalware}
                            </span>
                        </h3>
                        <p
                            className="link"
                            onClick={() => this.props.goToLink('scan', 'Scan.result', false)}
                        >
                            {(this.props.translations.TR_WITH_MALWARE)}
                        </p>
                    </div>
                    <div className="col-md-5 col-sm-4">
                        <h3>
                            <span
                                className="large-text link"
                                onClick={() => this.props.goToLink('scan', 'Scan.ios', false)}
                            >
                                {this.props.userDashBoardInfo.numberUsersWithInsecureIosConfig}
                            </span>
                        </h3>
                        <p
                            className="link"
                            onClick={() => this.props.goToLink('scan', 'Scan.ios', false)}
                        >
                            {(this.props.translations.TR_WITH_INSECURE_IOS)}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserInfoDashBoard;
