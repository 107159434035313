import Urls from "../Urls";
import {METHOD} from "@cuatroochenta/co-generic-request";
import {BaseGenericRequest} from "@cuatroochenta/co-admin-react-library";

interface TaskGetMessagesResponse {
    success: boolean,
    messages: string[]
}

export default class TaskGetMessages extends BaseGenericRequest<{}, TaskGetMessagesResponse> {

    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_GET_MESSAGES);
    }
}